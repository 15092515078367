import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import csvDownload from "json-to-csv-export"

import { useLanguage } from "../hooks"
import { withTranslationHOC } from "../i18n/with-translation"
import {
  ButtonGroup,
  Centered,
  ColumnContainer,
  Footer,
  IconButton,
  Loader,
  PageContainer,
  Report,
  Scroll,
  Title,
  TopBar,
  TopMenu,
} from "../components"
import { externalAddress, localAddress } from "../constants"
import { reportsRequest } from "../data/actions"
import { makeReportData } from "../utils"

function Reports() {
  const { t, lang } = useLanguage()
  const dispatch = useDispatch()

  const isLocal = useSelector(({ address }) => address)
  const [isLoaded, setLoaded] = useState(false)

  const reports = useSelector(({ reports }) => reports)
  const reportData = useMemo(() => makeReportData(reports, lang, t), [
    reports,
    lang,
    t,
  ])

  useEffect(() => {
    setLoaded(false)
    const apiAddress = isLocal ? localAddress : externalAddress

    dispatch(reportsRequest(apiAddress)).then(() => {
      setLoaded(true)
    })
  }, [dispatch, isLocal])

  const handleDownload = useCallback(() => {
    const names = {
      en: "report.csv",
      ru: "отчет.csv",
    }
    const filename = names[lang]
    csvDownload(reportData, filename)
  }, [reportData, lang])

  return (
    <PageContainer>
      <Helmet>
        <title>{t("menu.reports")}</title>
      </Helmet>

      {!isLoaded && <TopBar />}

      <Scroll>
        <ColumnContainer w="100vw" custom>
          <TopMenu fixed>
            <Title fontSize={24}>{t("menu.reports")}</Title>
            <ButtonGroup>
              <IconButton onClick={handleDownload} iconName="download" />
            </ButtonGroup>
          </TopMenu>
          {isLoaded && <Report data={reportData} />}
        </ColumnContainer>

        {!isLoaded && (
          <Centered>
            <Loader />
          </Centered>
        )}
      </Scroll>

      <Footer status={isLoaded ? t("status.done") : t("status.refresh")} />
    </PageContainer>
  )
}

export default withTranslationHOC(Reports)
